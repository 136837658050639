<template>
  <div class="page">
    <div class="shangtp"></div>
    <div class="bd">
      <van-form>
        <div class="wz">
          <van-field v-model="username" label="姓名:" />
          <div class="bq" @click="wt">(必填)</div>
        </div>

        <div class="wz">
          <van-field
            v-model="password"
            label="手机号:"
            :rules="[{ pattern, message: '请输入正确内容' }]"
          />
          <div class="bq">(必填)</div>
        </div>

        <div class="wz">
          <van-field
            readonly
            clickable
            name="area"
            :value="fieldValue"
            label="问题分类:"
            placeholder="点击选择"
            @click="show = true"
          />
          <div class="bq">(必填)</div>
        </div>

        <div class="wz">
          <van-field
            readonly
            clickable
            name="area"
            :value="fieldValues"
            label="所属乡镇(街道):"
            placeholder="点击选择"
            @click="shows = true"
          />
          <div class="bq">(必填)</div>
        </div>
        <div class="wz">
          <van-field
            v-model="message"
            rows="2"
            autosize
            label="建议意见："
            type="textarea"
            show-word-limit
          />
        </div>
        <div class="wz">
          <van-field name="uploader" label="文件上传:">
            <template class="11" #input>
              <van-uploader
                :after-read="afterRead"
                slot="input"
                v-model="fileList"
                :max-count="9"
                :before-delete="before"
              />
            </template>
          </van-field>
        </div>
        <div class="ll">
          <van-button
            round
            block
            type="info"
            :disabled="bton"
            native-type="submit"
            @click="sc"
            >提交建议</van-button
          >
        </div>
      </van-form>
    </div>
    <van-popup v-model="show" position="bottom">
      <van-picker
        show-toolbar
        :columns="wts"
        @confirm="onConfirm"
        @cancel="show = false"
      />
    </van-popup>
    <van-popup v-model="shows" position="bottom">
      <van-picker
        show-toolbar
        :columns="dqs"
        @confirm="onConfirms"
        @cancel="shows = false"
      />
    </van-popup>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      bton: false,
      username: "",
      password: "",
      show: false,
      shows: false,
      pattern: /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/,
      fieldValues: "",
      fieldValue: "",
      message: "",
      cascaderValue: "",
      wts: [],
      dqs: [],
      fileList: [],
      uploadImage: [],
      wtfl: [],
      ssjz: [],
      id: "",
      ids: "",
    };
  },
  created() {
    this.wt(); // 获取角色列表
    this.dq();
  },
  methods: {
    before(url, index) {
      console.log(index);
   let _thae = this.fileList;
   this.fileList.filter((item, indexs) => {
    if (index.index == indexs) {
     this.fileList.splice(indexs, 1);//splice 删除
    }
   });
   this.uploadImage.filter((item, indexs) => {
    if (index.index == indexs) {
     this.uploadImage.splice(indexs, 1);//splice 删除
    }
    
   });
   
  },

    sc() {
      if (this.username == "") {
        this.$toast("请选择姓名");
      } else {
        if (this.password == "") {
          this.$toast("请选择手机号");
        } else {
          if (this.fieldValue == "") {
            this.$toast("请选择问题分类");
          } else {
            if (this.fieldValues == "") {
              this.$toast("请选择所属街镇");
            } else {
              if (this.message == "") {
                this.$toast("请输入建议意见");
              } else {
                axios({
                  method: "POST",
                  url: "/xxtx/api/baseInfo/add",
                  data: {
                    address: this.ids,
                    otherInfo: this.message,
                    imageList: this.uploadImage,
                    questionType: this.id,
                    userMobile: this.password,
                    userName: this.username,
                  },
                }).then((res) => {
                  if (res.data.code == 200) {
                    this.$toast("提交建议成功，感谢您的配合");
                    this.fieldValues = "";
                    this.message = "";
                    this.uploadImage = [];
                    this.fieldValue = "";
                    this.password = "";
                    this.username = "";
                    this.fileList = [];
                  }
                });
              }
            }
          }
        }
      }
    },
    onConfirm(value, index) {
      this.fieldValue = value;
      this.id = this.wtfl[index].id;
      this.show = false;
    },
    onConfirms(value, index) {
      this.fieldValues = value;
      this.ids = this.ssjz[index].id;
      this.shows = false;
    },

    afterRead(file) {
      let formData = new FormData();
      formData.append("file", file.file);
      this.bton = true;
      axios({
        method: "POST",
        url: "/xxtx/api/baseInfo/upload",
        data: formData,
      }).then((res) => {
        if (res.data.code == 200) {
          let tp = res.data.data.path;
          this.uploadImage.push(tp);
          this.bton = false;
        }else{
          this.$toast("图片上传服务器失败");
        }
      });
    },
    wt() {
      axios({
        method: "GET",
        url: "/xxtx/api/questionType/list",
      }).then((res) => {
        this.wtfl = res.data.data;
        let newArr = res.data.data.map((item) => {
          // 每一项的id
          return item.typeName;
        });
        this.wts = newArr;
      });
    },
    dq() {
      axios({
        method: "GET",
        url: "/xxtx/api/addressInfo/list",
      }).then((res) => {
        this.ssjz = res.data.data;
        let newArr = res.data.data.map((item) => {
          // 每一项的id
          return item.addressInfo;
        });
        this.dqs = newArr;
      });
    },
  },
};
</script>

<style>
* {
  padding: 0;
  margin: 0;
}
.page {
  width: 100%;
  margin: 0 auto;
}
.ll {
  padding-left: 0.5rem;
}
.bd {
  width: 92%;
}
.shangtp {
  background: url(../../mqfx/src/state/tp.png) no-repeat center;
  width: 9.2rem;
  background-color: #fff;
  height: 12.6667rem;
  margin-left: 0.45rem;
  margin-top: 0.2rem;
  background-size: 100%;
}
.van-cell {
  font-size: 0.3733rem;
}
.van-field__control {
  text-align: center;
  color: #000;
}
.sc {
  padding-left: 0.4rem;
}
.tp {
  padding-left: 0.4rem;
  color: #000;
  font-weight: 500;
  width: 5.5em;
  font-size: 0.3733rem;
}
.bq {
  width: 15%;
  font-size: 0.3733rem;
  margin-top: 0.4rem;
}
.wz {
  margin-left: 0.3rem;
  width: 100%;
  display: flex;
}
.van-field__control {
  border: 0.5px #ccc solid;
  border-radius: 4px;
}
.van-field__label {
  color: #000;
  font-weight: 500;
  width: 5.5em;
}
</style>