import Vue from 'vue'
import App from './App.vue'
import Vant from 'vant'
import 'amfe-flexible'
import axios from 'axios'
Vue.prototype.$http = axios
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'; //引入element-ui的样式文件
Vue.use(ElementUI)//通过这个命令使所有的element-ui标签(<el-*></el-*>)可被解析
Vue.use(Vant)
Vue.config.productionTip = false

new Vue({
  render: h => h(App)
}).$mount('#app')
